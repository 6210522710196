import React from "react";
import {BizVideoBackground} from "../../shares/default/bizVideoBackground/BizVideoBackground";
import {IndexV1Section1} from "./section1/IndexV1Section1";
import {IndexMySMS} from "./mysms/IndexMySMS";

export const IndexDefault = () => {
    return (
        <BizVideoBackground>
            {(window.location.host === "my-2017.com" || window.location.host === "www.my-2017.com") && (
                <IndexMySMS/>)}
            {!(window.location.host === "my-2017.com" || window.location.host === "www.my-2017.com") && (
                <IndexV1Section1/>)}

        </BizVideoBackground>
    );
};

