import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {usePath, useRoutes} from "hookrouter";
import {AppAuth, SystemState} from "../redux/System";
import {UserState} from "../redux/User";
import {NotFound} from "./system/notFound/NotFound";
import {NotAuth} from "./system/notAuth/NotAuth";
import {CreateRouter} from "./Router";
import {Layout} from "./layout/Layout";
import {GlobalVars} from "../globalVars/globalVars";
import {BizBackdrop} from "./shares/default/bizBackdrop/BizBackdrop";
import {useReactiveVar} from "@apollo/client";
import {BizDirectNoticeModal} from "./shares/default/bizDirectNoticeModal/BizDirectNoticeModal";
import {LayoutUserAlert} from "./shares/default/userAlert/LayoutUserAlert";
import {LayoutAction} from "../redux/Layout";

export const App = () => {
    const system = useSelector<any, SystemState>(state => state.system);
    const user = useSelector<any, UserState>(state => state.user);
    const page = useRoutes(CreateRouter(user)) || <NotFound/>;
    const hasLoadingView = useReactiveVar(GlobalVars.layout.loadingView);
    const env = useReactiveVar(GlobalVars.auth.env);
    const isKingdom = process.env.REACT_APP_APIKEY === "e4d9b406-eb90-4b84-b49d-3e56d0d1bcbd";
    const isMysms = process.env.REACT_APP_APIKEY === "d555dc05-9cfb-4b5c-aa31-efd1b4b132b3";
    const path = usePath();
    const dispatch = useDispatch();

    useEffect(() => {
        if (path === "/") {
            dispatch(LayoutAction.setAppBarTransparent(true));
            dispatch(LayoutAction.setTopMargin(false));
        }
    }, [])


    useEffect(() => {
        if (!env || isKingdom) {
            return;
        }

        // twak 초기화
        const s1 = document.createElement('script');
        const s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = env.twak;
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');

        if (s0.parentNode) {
            s0.parentNode.insertBefore(s1, s0);
        }

    }, [env]);

    return (

        <Fragment>
            {system.appAuth === AppAuth.Ok &&
                <Fragment>
                    <Layout>
                        {user.isLogin && <LayoutUserAlert/>}
                        <BizBackdrop loading={hasLoadingView}/>
                        <BizDirectNoticeModal/>
                        {page}
                        {isKingdom && <div style={{
                            position: "fixed",
                            bottom: 20, right: 20,
                            zIndex: 99
                        }}><a href={"https://t.me/kingdom_smsM"} target={"_blank"}>
                            <img src={"/assets/img/telegram.png"} style={{width: "auto", height: 40}} alt={"telegram"}/></a>
                        </div>}
                        {isMysms && <div style={{
                            position: "fixed",
                            bottom: 20, right: 20,
                            zIndex: 99
                        }}><a href={"https://t.me/kingdom_smsM"} target={"_blank"}>
                            <img src={"/assets/img/mySms-telegram.png"} style={{width: "auto", height: 80}} alt={"telegram"}/></a>
                        </div>}
                    </Layout>
                </Fragment>
            }

            {system.appAuth === AppAuth.Ban &&
                <NotAuth/>
            }

            {system.appAuth === AppAuth.ShutDown &&
                <NotAuth/>
            }

            {system.appAuth === AppAuth.Loading &&
                <div>시스템 로딩중입니다.</div>
            }

        </Fragment>

    )
}
